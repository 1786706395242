/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Inscription } from 'models/voteModel';
import { normalize, schema } from 'normalizr';
import { campagnesSchema } from '../adminCampagnes/schemas';
import api, {
  AddInscriptionParameters,
  EditInscriptionParameters,
} from './api';

// TODO: Generalize
const inscriptionSchema = new schema.Entity<Inscription>(
  'Inscriptions',
  {},
  {
    idAttribute: 'Id',
  }
);

export const fetchAllInscriptions = createAsyncThunk(
  `admin/inscription/fetchAllInscriptions`,
  async (parameters: { token: string; campagneId: number }) => {
    const response = await api.fetchAllInscriptions(parameters);
    return normalize(response, [inscriptionSchema]);
  }
);

export const donnerDroitVote = createAsyncThunk(
  `admin/inscription/donnerDroitVote`,
  async (parameters: { token: string; id: number }) => {
    const response = await api.donnerDroitVote(parameters);
    return normalize(response, inscriptionSchema);
  }
);

export const retirerDroitVote = createAsyncThunk(
  `admin/inscription/retirerDroitVote`,
  async (parameters: { token: string; id: number }) => {
    const response = await api.retirerDroitVote(parameters);
    return normalize(response, inscriptionSchema);
  }
);

export const donnerDroitVoteATous = createAsyncThunk(
  `admin/inscription/donnerDroitVoteATous`,
  async (parameters: { token: string; campagneId: number }) => {
    const response = await api.donnerDroitVoteATous(parameters);
    return normalize(response, [inscriptionSchema]);
  }
);

export const retirerDroitVoteATous = createAsyncThunk(
  `admin/inscription/retirerDroitVoteATous`,
  async (parameters: { token: string; campagneId: number }) => {
    const response = await api.retirerDroitVoteATous(parameters);
    return normalize(response, [inscriptionSchema]);
  }
);

export const synchroniserDroitVoteAvecPresences = createAsyncThunk(
  `admin/inscription/synchroniserDroitVoteAvecPresences`,
  async (parameters: { token: string; campagneId: number }) => {
    const response = await api.synchroniserDroitVoteAvecPresences(parameters);
    return normalize(response, [inscriptionSchema]);
  }
);

export const importInscriptions = createAsyncThunk(
  `admin/inscription/importInscriptions`,
  async (parameters: { token: string; file: File; campagneId: number }) => {
    const response = await api.importInscriptions(parameters);
    return normalize(response, [inscriptionSchema]);
  }
);

export const genererBulletinsPourInscriptions = createAsyncThunk(
  `admin/inscription/genererBulletins`,
  async (parameters: { campagneId: number; token: string; ids: number[] }) =>
    api.genererBulletinsPourInscriptions(parameters)
);

export const genererBulletinPourUneInscription = createAsyncThunk(
  `admin/inscription/genererUnBulletin`,
  async (parameters: { inscriptionId: number; token: string }) =>
    api.genererBulletinPourUneInscription(parameters)
);

export const demarrerPrisePresences = createAsyncThunk(
  `admin/inscription/demarrerPrisePresences`,
  async (parameters: {
    campagneId: number;
    reponseChallenge: number;
    token: string;
  }) => {
    const response = await api.demarrerPrisePresences(parameters);
    return normalize(response, campagnesSchema);
  }
);

export const arreterPrisePresences = createAsyncThunk(
  `admin/inscription/arreterPrisePresences`,
  async (parameters: { campagneId: number; token: string }) => {
    const response = await api.arreterPrisePresences(parameters);
    return normalize(response, campagnesSchema);
  }
);

export const admettreInscription = createAsyncThunk(
  `admin/inscription/admettre`,
  async (parameters: { token: string; id: number; origin: string }) => {
    const response = await api.admettreInscription(parameters);
    return normalize(response, inscriptionSchema);
  }
);

export const rejetterInscription = createAsyncThunk(
  `admin/inscription/rejetter`,
  async (parameters: { token: string; id: number; reponse?: string }) => {
    await api.rejetterInscription(parameters);
    return parameters.id;
  }
);

export const addInscription = createAsyncThunk(
  `soutien/Inscription`,
  async (parameters: AddInscriptionParameters) => {
    const response = await api.addInscription(parameters);
    return response;
  }
);

export const editInscription = createAsyncThunk(
  `admin/inscriptions/editInscription`,
  async (parameters: {
    token: string;
    inscription: EditInscriptionParameters;
  }) => api.editInscription(parameters)
);

export const updateCourriel = createAsyncThunk(
  `admin/inscriptions/updateCourriel`,
  async (parameters: {
    token: string;
    inscriptionId: number;
    courriel: string;
  }) => api.updateCourriel(parameters)
);


export const radierInscription = createAsyncThunk(
  `admin/inscriptions/radier`,
  async (parameters: { token: string; inscriptionId: number }) =>
    api.radierInscription(parameters)
);

export const deleteInscription = createAsyncThunk(
  `admin/inscriptions/delete`,
  async (parameters: { token: string; inscriptionId: number }) =>
    api.deleteInscription(parameters)
);
