import api, {Api} from 'backend/Api';
import {
    CampagneListItem, ClientListItem,
    ScrutinAdmin,
    ScrutinAdminReponse,
    ScrutinListItem,
} from 'models/adminModel';
import qs from 'qs';
import {
    UpdateCampagneFormFields,
    UpdateEspacePubliqueParameters,
    UpdateQuestionsScrutinFormFields,
    UpdateScrutinFormFields,
} from './actions';

const apiRoute = `adminCampagnes`;

const fetchAllCampagnes = (token: string) =>
    api
        .get<CampagneListItem[]>(apiRoute, Api.bearerAuth(token))
        .then((response) => response.data);

const fetchAllClients = (token: string) =>
    api
        .get<ClientListItem[]>(`${apiRoute}/GetAllClients`, Api.bearerAuth(token))
        .then((response) => response.data);

const fetchCampagneById = (id: number, token: string) =>
    api
        .get<CampagneListItem>(`${apiRoute}/${id}`, Api.bearerAuth(token))
        .then((response) => response.data);

const fetchCampagneByScrutinId = (id: number, token: string) =>
    api
        .get<CampagneListItem>(`${apiRoute}/ByScrutin/${id}`, Api.bearerAuth(token))
        .then((response) => response.data);

const fetchScrutinById = (id: number, token: string) =>
    api
        .get<ScrutinAdmin>(`${apiRoute}/Scrutin/${id}`, Api.bearerAuth(token))
        .then((response) => response.data);

const addCampagne = (
    parameters: { token: string } & UpdateCampagneFormFields
) => {
    const {token, ...data} = parameters;
    return api
        .post<CampagneListItem>(apiRoute, qs.stringify(data), Api.bearerAuth(token))
        .then((response) => response.data);
};

const deleteCampagne = (parameters: { id: number; token: string }) => {
    const {token, id} = parameters;
    return api
        .delete(`${apiRoute}/${id}`, Api.bearerAuth(token))
        .then((response) => response.data);
};

const deleteScrutin = (parameters: { id: number; token: string }) => {
    const {token, id} = parameters;
    return api
        .delete(`${apiRoute}/DeleteScrutin/${id}`, Api.bearerAuth(token))
        .then((response) => response.data);
};

const deleteBulletins = (parameters: { id: number; token: string }) => {
    const {token, id} = parameters;
    return api
        .delete<CampagneListItem>(
            `${apiRoute}/DeleteBulletins/${id}`,
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const addScrutin = (parameters: {
    titre: string;
    idUnique: string;
    campagneId: number;
    typeScrutin: string;
    question: string;
    token: string;
}) => {
    const {token, ...data} = parameters;
    return api
        .post<ScrutinListItem>(
            `${apiRoute}/scrutin`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const updateCampagne = (
    parameters: {
        id: number;
        token: string;
    } & UpdateCampagneFormFields
) => {
    const {token, ...data} = parameters;
    return api
        .put<CampagneListItem>(
            `${apiRoute}/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const updateEspacePublique = (
    parameters: {
        id: number;
        token: string;
    } & UpdateEspacePubliqueParameters
) => {
    const {token, ...data} = parameters;
    return api
        .put<CampagneListItem>(
            `${apiRoute}/EspacePublique/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

// TODO: Bouger dans une autre feature?

const activateScrutin = (id: number, nbConnections: number, token: string) => {
    const data = {id, nbConnections, token};
    return api
        .post<ScrutinAdmin>(
            `admin/ActiverScrutin`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const reprendreScrutin = (id: number, token: string) => {
    const data = {id, token};
    return api
        .post<ScrutinAdmin>(
            `admin/ReprendreScrutin`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const closeScrutin = (id: number, token: string) => {
    const data = {id, token};
    return api
        .post<ScrutinAdmin>(
            `admin/FermerScrutin`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const publishScrutin = (id: number, token: string) => {
    const data = {id, token};
    return api
        .post<ScrutinAdmin>(
            `admin/PublierScrutin`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const forcerStatutScrutin = (
    id: number,
    referenceId: number,
    token: string
) => {
    const data = {id, refId: referenceId, token};
    return api
        .post<ScrutinAdmin>(
            `admin/ForcerStatutScrutin`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const diffuserResultats = (id: number, token: string) => {
    const data = {id, token};
    return api
        .post<ScrutinAdmin>(
            `admin/DiffuserResultats`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const genererBulletins = (parameters: {
    campagneId: string;
    quantite: number;
    segments: string[];
    token: string;
    tokenSeulement?: boolean;
}) => {
    const {token, ...data} = parameters;
    return api
        .post<string[] | { token: string }>(
            `admin/GenererBulletins`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const fetchAllSegments = (campagneId: number, token: string) =>
    api
        .get<string[]>(`${apiRoute}/Segments/${campagneId}`, Api.bearerAuth(token))
        .then((response) => response.data);

const setTheme = (parameters: {
    id: number;
    themeId: number;
    urlLogo: string | null;
    token: string;
}) => {
    const {token, ...data} = parameters;
    return api
        .patch<CampagneListItem>(
            `${apiRoute}/SetCampagneTheme/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const switchToCourriel = (parameters: { id: number; token: string }) => {
    const {token, ...data} = parameters;
    return api
        .patch<CampagneListItem>(
            `${apiRoute}/SwitchToCourriel/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const switchToEnDirect = (parameters: { id: number; token: string }) => {
    const {token, ...data} = parameters;
    return api
        .patch<CampagneListItem>(
            `${apiRoute}/SwitchToEnDirect/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const updateScrutin = (
    parameters: {
        id: number;
        token: string;
    } & UpdateScrutinFormFields
) => {
    const {token, ...data} = parameters;
    return api
        .put<ScrutinAdmin>(
            `${apiRoute}/Scrutin/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const updateQuestionsScrutin = (
    parameters: {
        id: number;
        token: string;
    } & UpdateQuestionsScrutinFormFields
) => {
    const {token, ...data} = parameters;
    return api
        .put<ScrutinAdmin>(
            `${apiRoute}/QuestionsScrutin/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const addCandidatReponse = (parameters: {
    id: number;
    token: string;
    nom: string;
    prenom: string;
}) => {
    const {token, ...data} = parameters;
    return api
        .put<ScrutinAdmin>(
            `${apiRoute}/AddCandidatReponse/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const setOrdreScrutins = (parameters: {
    id: number;
    scrutinsIds: number[];
    token: string;
}) => {
    const {token, ...data} = parameters;
    return api
        .patch<CampagneListItem>(
            `${apiRoute}/SetOrdreScrutins/${parameters.id}`,
            qs.stringify(data),
            Api.bearerAuth(token)
        )
        .then((response) => response.data);
};

const fetchVentilationScrutin = (id: number, token: string) =>
    api
        .get<Record<string, ScrutinAdminReponse[]>>(
            `${apiRoute}/fetchVentilationScrutin/${id}`,
            Api.bearerAuth(token)
        )
        .then((response) => response.data);

const fetchApiInfo = () =>
    api
        .get<{ ASPNETCORE_ENVIRONMENT: string }>(`admin/ApiInfo`)
        .then((response) => response.data);

const dupliquerCampagne = async (parameters: {
    token: string;
    campagneId: number,
    titre: string,
    scrutins: number[]
}) => {
    const {token, campagneId, ...data} = parameters;
    const response = await api.post<CampagneListItem>(
        `${apiRoute}/dupliquer/${campagneId}`,
        qs.stringify(data),
        Api.bearerAuth(token)
    );
    return response.data;

}

export default {
    fetchAllCampagnes,
    fetchCampagneById,
    addCampagne,
    addScrutin,
    fetchCampagneByScrutinId,
    activateScrutin,
    reprendreScrutin,
    closeScrutin,
    diffuserResultats,
    genererBulletins,
    setTheme,
    setOrdreScrutins,
    updateScrutin,
    updateCampagne,
    updateQuestionsScrutin,
    addCandidatReponse,
    publishScrutin,
    forcerStatutScrutin,
    deleteCampagne,
    deleteBulletins,
    fetchScrutinById,
    switchToCourriel,
    switchToEnDirect,
    fetchAllSegments,
    fetchVentilationScrutin,
    fetchApiInfo,
    updateEspacePublique,
    dupliquerCampagne,
    deleteScrutin,
    fetchAllClients,
};
