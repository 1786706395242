import DateFnsUtils from '@date-io/moment';
import {colors, CssBaseline, ThemeProvider} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import ErrorBoundary from 'components/ErrorBoundary';
import SuspenseMessage from 'components/SuspenseMessage';
import {LocalisationSetter} from 'dates';
import GlobalStyles from 'GlobalStyles';
import {createBrowserHistory} from 'history';
import {SnackbarProvider} from 'notistack';
import {lazy} from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import {Provider} from 'react-redux';
import {Route, Router, Switch} from 'react-router-dom';
import theme from 'theme';
import './config.json';
import './i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {store} from './store';

const Admin = lazy(() => import('./moduleAdmin/AdminModule'));
const Home = lazy(() => import('features/home/pages/Home'));
const Soutien = lazy(() => import('features/soutien/pages/Index'));
const Login = lazy(() => import('features/home/pages/Login'));


// eslint-disable-next-line  import/prefer-default-export
export const history = createBrowserHistory();

// default colors
const primary = colors.cyan[800];
const secondary = colors.teal[400];



ReactDOM.render(
  // <React.StrictMode>
  <HttpsRedirect>

    <Provider store={store}>
      <ThemeProvider theme={theme(primary, secondary)}>
        <CssBaseline />
        <GlobalStyles />
        <SnackbarProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ErrorBoundary>
              <LocalisationSetter />
              <Router history={history}>
                <SuspenseMessage>
                  <Switch>
                    <Route path="/admin" component={Admin} />
                    <Route path="/soutien/:campagneUniqueId" component={Soutien} />
                    <Route path="/:campagneUniqueId" component={Home} />
                    <Route path='/' component={Login} />
                  </Switch>
                </SuspenseMessage>
              </Router>

            </ErrorBoundary>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </HttpsRedirect>,
  // </React.StrictMode>,
  document.querySelector('#root') || document.createElement('div') // for testing purposes

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals();