import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import apiConfig from './apiconfig';

export class Api {
  private api: AxiosInstance;

  public static bearerAuth = (token: string): AxiosRequestConfig => ({
    headers: { Authorization: `Bearer ${token}` },
  });

  public constructor(config: AxiosRequestConfig = apiConfig) {
    this.api = axios.create(config);

    // this middleware is been called right before the http request is made.
    this.api.interceptors.request.use((request: AxiosRequestConfig) => ({
      ...request,
    }));

    // this middleware is been called right before the response is get it by the
    // method that triggers the request
    this.api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        switch (error.response?.status) {
          case 400:
          case 401:
            return Promise.reject(
              new Error(error.response?.data.title ||  error.response?.data || error.response?.statusText)
            );
          default:
            return Promise.reject(
              new Error(
                error.response?.data.detail ||
                  error.response?.data.title ||
                  error.response?.data ||
                  error.response ||
                  error.message
              )
            );
        }
      }
    );
  }

  public getUri = (config?: AxiosRequestConfig): string =>
    this.api.getUri(config);

  public request = <T, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> => this.api.request(config);

  public get = <T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> => this.api.get(url, config);

  public delete = <T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> => this.api.delete(url, config);

  public head = <T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> => this.api.head(url, config);

  public post = <T, R = AxiosResponse<T>>(
    url: string,
    data?: string | FormData,
    config?: AxiosRequestConfig
  ): Promise<R> => this.api.post(url, data, config);

  public put = <T, R = AxiosResponse<T>>(
    url: string,
    data?: string,
    config?: AxiosRequestConfig
  ): Promise<R> => this.api.put(url, data, config);

  public patch = <T, R = AxiosResponse<T>>(
    url: string,
    data?: string,
    config?: AxiosRequestConfig
  ): Promise<R> => this.api.patch(url, data, config);
}

export default new Api();
